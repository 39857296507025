<template>
  <div class="refundDepositPage">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
      :border="false"
      :fixed="true"
      :placeholder="true"
    />
    <div class="refundDepositContainer">
      <div class="refundDepositCard">
        <div class="cardItemTitle">
          <div class="line"></div>
          <div class="title">诚心卖保证金怎么退还</div>
        </div>
        <div class="cardItemBody">
          诚心卖服务旨在促进每位卖家订单成交，平台并不以此盈利，当您的商品成功出售、自主删除，保证金自动退到余额；<br /><br
            class="br"
          />且若您在出售中想关闭诚心卖服务，您可先下架商品，关闭服务后，保证金同样自动退还；<br /><br
            class="br"
          />若您的商品最终未成功发布（包含退出发布或审核失败而删除），保证金将自动退还至余额；<br /><br
            class="br"
          />在买家下单支付后，若由于您的原因而导致订单退款，则视具体情况对保证金进行扣除，所扣金额将全部赔付给买家。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundDeposit",
  data() {
    return {
      title: "保证金退还",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.refundDepositPage {
  width: 375px;
  height: 100vh;
  background: #f5f5f5;
  .refundDepositContainer {
    .refundDepositCard {
      width: 345px;
      //   height: 284px;
      background: #ffffff;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 12px;
      padding: 16px 15px;
      box-sizing: border-box;
      .cardItemTitle {
        display: flex;
        align-items: center;
        .line {
          width: 3px;
          height: 16px;
          background: #00a8ff;
          border-radius: 2px;
        }
        .title {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 11px;
        }
      }
      .cardItemBody {
        width: 315px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 18px;
        margin-top: 16px;
        .br {
          display: block;
          height: 12px;
        }
      }
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: black;
  font-size: 16px;
}
</style>
